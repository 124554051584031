<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div v-if="step === 0">
      <SearchList v-if="branch_updated.organization === null"
        ph="Select Organization"
        :list="organization_list"
        :pageTotal="pageTotalOrganization"
        @search="onSearchOrganization"
        @focus="onFocusOrganization"
        @select="onSelectOrganization"
        @page="onPageOrganization"
      />
      <CCard v-else>
        <CCardBody>
          <div class="my-2">Organization</div>
          <CWidgetIcon :header="branch_updated.organization_name" text="" color="info" class="my-0">
            <CIcon name="cil-building" width="24" />
          </CWidgetIcon>
          <div v-if="branch_updated.organization" class="pl-4 my-2">
            <SearchList
              v-if="branch_updated.region === null"
              ph="Select Region"
              :list="region_list"
              :pageTotal="pageTotalRegion"
              @search="onSearchRegion"
              @focus="onFocusRegion"
              @select="onSelectRegion"
              @page="onPageRegion"
            />
            <div v-else>
              <CWidgetIcon :text="branch_updated.region_name" color="dark">
                <CIcon name="cil-location-pin" width="16" />
              </CWidgetIcon>
            </div>
          </div>
          <CButton style="float: right;" color="danger" shape="pill" @click="onReset">Reset</CButton>
        </CCardBody>
      </CCard>
    </div>
    <CCard v-if="step === 1">
      <CCardBody>
        <CInput
          label="Name"
          placeholder="Branch name"
          v-model="branch_updated.name"
        />
        <CInput
          label="Address 1"
          placeholder="Street, City/State, Country/Region"
          v-model="branch_updated.addr1"
        />
        <CInput
          label="Address 2"
          placeholder="Apt, Suite, Building"
          v-model="branch_updated.addr2"
        />
      </CCardBody>
    </CCard>
    <CCard v-if="step === 2">
      <CCardBody>
        <CInput
          type="number"
          label="Latitude"
          placeholder="Latitude"
          v-model="branch_updated.lat"
        />
        <CInput
          type="number"
          label="Longitude"
          placeholder="Longitude"
          v-model="branch_updated.lng"
        />
      </CCardBody>
      <CCardFooter>
        <google-map
          :search="search"
          :address="address"
          @updatedLatLng="updatedLatLng"
          :lat="Number(branch_updated.lat)" :lng="Number(branch_updated.lng)"
          >
        </google-map>
      </CCardFooter>
    </CCard>
    <template #header>
      <h6 class="modal-title">{{title}}  {{" - STEP ("+(step+1)+"/3)"}}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step === 0" @click="onCancel" color="secondary">Cancel</CButton>
      <CButton v-if="step === 0" @click="onNext" color="primary">Next</CButton>
      <CButton v-if="step === 1" @click="onPrev" color="secondary">Prev</CButton>
      <CButton v-if="step === 1" @click="onNext" color="primary">Next</CButton>
      <CButton v-if="step === 2" @click="onPrev" color="secondary">Prev</CButton>
      <CButton v-if="step === 2" @click="onSubmit" color="primary">Submit</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'
import GoogleMap from '@/components/GoogleMapLoader.vue'

export default {
  name: 'BrnachAdd',
  components: {
    SearchList,
    GoogleMap,
  },
  props : {
    branch : {type:Object, default:function(){return null;}},
    title : {type:String, default:""},
  },
  data() {
    return {
      show: false,

      name: null,
      addr1: null,
      addr2: null,
      lat: null,
      lng: null,

      searchTextRegion: null,
      region_list: [],
      pageTotalRegion: 1,
      pageCurrentRegion: 1,

      searchTextOrganization: null,
      organization_list: [],
      pageTotalOrganization: 1,
      pageCurrentOrganization: 1,

      branch_updated : {
        id:null,
        name : null,
        addr1 : null,
        addr2 : null,
        lat : null,
        lng : null,
        region : null, // region_id
        region_name : null,
        organization: null,
        organization_name: null
      },
      search:true, // Add => Search On , Edit => Search Off

      notification: '',
      dismissCountDown: 0,
      step: 0
    }
  },
  computed:{
    address : function(){
      return `${this.branch_updated.addr1+" "+this.branch_updated.addr2}`
    }
  },
  methods: {
    open() {
      this.initialize();
      this.show = true;
    },
    initialize(){
      if(this.branch) { // edit
        this.branch_updated = JSON.parse(JSON.stringify(this.branch));
        this.search = false;
      } else { // add
        this.branch_updated = {
          id: null,
          name : null,
          addr1 : null,
          addr2 : null,
          lat : null,
          lng : null,
          organization: null,
          organization_name: null,
          region : null,
          region_name : null,
        };
      }
      this.getOrganization();
      this.step = 0;
    },
    getOrganization(){
      let query_string = '';
      const params = {
        search: this.searchTextOrganization,
        page: this.pageCurrentOrganization
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/organizations/${query_string}`)
        .then(result => {
          this.organization_list = result.data.list;
          this.pageTotalOrganization = result.data.page.total;
          this.pageCurrentOrganization = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getRegion(){
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchTextRegion,
        page: this.pageCurrentRegion,
        // id: this.branch_updated.organization,  
        // 나중에 권한 생길경우에, organization 에 속해있는 region만 발생하도록 수정 필요
      };
      if (this.branch_updated.organization) {
        params.organization = this.branch_updated.organization;
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/install/regions/${query_string}`)
        .then(result => {
          this.region_list = result.data.list;
          this.pageTotalRegion = result.data.page.total;
          this.pageCurrentRegion = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel() {
      this.show = false;

      this.branch_updated.name = null;
      this.branch_updated.addr1 = null;
      this.branch_updated.addr2 = null;
      this.branch_updated.lat = null;
      this.branch_updated.lng = null;
      this.branch_updated.region = null;
      this.branch_updated.region_name = null;
      this.branch_updated.organization = null;
      this.branch_updated.organization_name = null;
      this.region_list = [];
      this.organization_list = [];

      this.step = 0;
    },
    onNext() {
      if (this.step === 0) {
        if (!this.branch_updated.organization) {
          this.notification = "Organization is required.";
          this.dismissCountDown = 3;
          return;
        }
      }
      if (this.step === 1) {
        if(!this.validate_basic()){
          return;
        }
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },
    onSubmit() {
      if(this.validate_submit()){
        this.show = false;
        this.$emit('payload', {
          name: this.branch_updated.name,
          addr1: this.branch_updated.addr1,
          addr2: this.branch_updated.addr2,
          lat: this.branch_updated.lat,
          lng: this.branch_updated.lng,
          region: this.branch_updated.region,
          organization: this.branch_updated.organization
        });
      }else{
        return;
      }
      
    },
    validate_basic(){
      if (!this.branch_updated.name) {
        this.notification = 'Name is required';
        this.dismissCountDown = 3;
        return false;
      }
      if (this.branch_updated.name.length < 4) {
        this.notification = 'Name is too short';
        this.dismissCountDown = 3;
        return false;
      }
      if (this.branch_updated.name === null) {
        this.notification = "Name is required.";
        this.dismissCountDown = 3;
        return false;
      }
      if (this.branch_updated.addr1 === null) {
        this.notification = "Address error.";
        this.dismissCountDown = 3;
        return false;
      }
      if (this.branch_updated.addr1 === "") {
        this.notification = "Address is required.";
        this.dismissCountDown = 3;
        return false;
      }
      return true;
    },
    validate_submit(){
      if(JSON.stringify(this.branch)===JSON.stringify(this.branch_updated)){
          this.notification = 'Have not changed the branch'
          this.dismissCountDown = 3;
          return false;
      }
      if (!this.branch_updated.lat || !this.branch_updated.lng) {
        this.notification = 'Branch location error';
        this.dismissCountDown = 3;
        return false;
      }
      try {
        this.branch_updated.lat = Number(this.branch_updated.lat);
        this.branch_updated.lng = Number(this.branch_updated.lng);
      } catch(e) {
        this.notification = 'Branch location format error';
        this.dismissCountDown = 3;
        return false;
      }
      return true;
    },
    onSearchOrganization: function(text) {
      this.searchTextOrganization = text;
      this.getOrganization();
      // let query_string = '';
      // const params = {
      //   search: text,
      //   page: 1
      // };
      // if (params) {
      //   query_string = '?' + qs.stringify(params)
      // }
      // axios.get(`/api/organizations/${query_string}`)
      //   .then(result => {
      //     this.organization_list = result.data.list;
      //   })
    },
    onFocusOrganization: function() {
      this.getOrganization();
      // let query_string = '';
      // const params = {
      //   search: '',
      //   page: 1
      // };
      // if (params) {
      //   query_string = '?' + qs.stringify(params)
      // }
      // axios.get(`/api/organizations/${query_string}`)
      //   .then(result => {
      //     this.organization_list = result.data.list;
      //   })
    },
    onSelectOrganization: function(id) {
      let selected_organization = this.organization_list.find(el=>el.id===id);
      this.branch_updated.organization = selected_organization.id;
      this.branch_updated.organization_name = selected_organization.name;
      this.getRegion();
    },
    onSearchRegion: function(text) {
      this.searchTextRegion = text;
      this.getRegion();
      // let query_string = '';
      // const params = {
      //   search: text,
      //   page: 1
      // };
      // if (params) {
      //   query_string = '?' + qs.stringify(params)
      // }
      // axios.get(`/api/install/regions/${query_string}`)
      //   .then(result => {
      //     this.region_list = result.data.list;
      //   })
    },
    onFocusRegion: function() {
      this.getRegion();
      // let query_string = '';
      // const params = {
      //   search: '',
      //   page: 1
      // };
      // if (params) {
      //   query_string = '?' + qs.stringify(params)
      // }
      // axios.get(`/api/install/regions/${query_string}`)
      //   .then(result => {
      //     this.region_list = result.data.list;
      //   })
    },
    onSelectRegion: function(id) {
      let selected_region = this.region_list.find(el=>el.id===id);
      this.branch_updated.region = selected_region.id;
      this.branch_updated.region_name = selected_region.name;
    },

    updatedLatLng : function(lat_lng){
      this.branch_updated.lat = lat_lng.lat;
      this.branch_updated.lng = lat_lng.lng;
    },

    onPageOrganization: function(p) {
      this.pageCurrentOrganization = p;
      this.getOrganization();
    },
    onPageRegion: function(p) {
      this.pageCurrentRegion = p;
      this.getRegion();
    },

    onReset() {
      this.branch_updated.organization = null;
      this.branch_updated.organization_name = null;
      this.branch_updated.region = null;
      this.branch_updated.region_name = null;
      this.pageCurrentOrganization = 1;
      this.pageCurrentRegion = 1;
      this.searchTextOrganization = null;
      this.searchTextRegion = null;
      this.region_list = [];
    }
  }
}
</script>