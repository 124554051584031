<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />
    <BranchView v-if="is_view"
      :branch="selected_branch"
      @movePage="onMovePage"
      @onEdit="onEditPayload"
    >
    </BranchView>
    <Search @search="onSearch" v-else>
      <BranchForm title="New Branch" ref="AddModal" @payload="onAddPayload" />
      <BulkUploadResult ref="BulkResultModal" title="Result" :result="bulkUploadContext" />
      <ConfirmModal ref="ConfirmModal" :content="`${bulkUploadContext.items.length} items will be uploaded. Continue?`" @ok="bulkSubmit" />
      <ProgressModal ref="ProgressModal" :counter="UploadProgressCounter" @cancel="onStopBulkUpload" />
      <CRow>
        <CCol sm="12">
          <CTableWrapper 
            :items="list"
            :fields="FIELDS"
            header-icon="cil-room"
            :add-btn="true"
            :count="count"
            :caption="$t('cloud.title.branch')"
            hover
            small
            fixed
            :importBtn="false"
            :checkable="false"
            @add="onAdd"
            @view="onView"
            @delete="onDelete"
            @delete-checked-items="onDeleteCheckedItems"
          />
        </CCol>
      </CRow>
      <CRow class="justify-content-end" v-if="pageTotal > 1">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
      <input type="file" id="_upload_file" hidden accept=".csv" @change="onFileChange"/>
    </Search>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import utility from '@/utility'
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import BulkUploadResult from '@/components/modals/BulkResultModal.vue'
import CTableWrapper from '@/components/EdxTable'
import BranchView from './BranchView.vue'
import BranchForm from './BranchForm.vue'
import { mapState } from 'vuex'

export default {
  name: 'Branches',
  components: {
    Search,
    CTableWrapper,
    BulkUploadResult,
    ConfirmModal,
    ProgressModal,
    BranchView,
    BranchForm,
  },
  data () {
    return {
      FIELDS: [
        // {key: 'checkbox', label: '', _style:'width:50px;'},
        {key: 'organization_name', label: 'Organization', _classes: 'align-middle'},
        {key: 'name', label: 'Name', _classes: 'align-middle'},
        {key: 'region_name', label: 'Region', _classes: 'align-middle'},
        {key: 'address', label: 'Address', _classes: 'align-middle'},
        {key: 'sid', label: 'Code', _classes: 'align-middle'},
        {key: 'ops', label: 'Operations', _style: 'width: 100px;'}
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      bulkUploadContext: {
        header: [],
        items: [],
        current: 0,
        maxPage: 0,
        okCnt: 0,
        errors: []
      },

      confirm_show: false,

      // view 관련 데이터
      is_view : false,
      selected_branch : null,
      selected_branch_index : null, 
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    UploadProgressCounter() {
      return parseInt((Math.min(this.bulkUploadContext.current, this.bulkUploadContext.maxPage) / Math.max(this.bulkUploadContext.maxPage, 1)) * 100);
    }
  },
  methods: {
    getList: function() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/install/branches/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          for(let el of result.data.list){
            el['address'] = el['addr1'] + ' ' + el['addr2'];
          }
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addBulk: function() {
      if (this.bulkUploadContext.current > this.bulkUploadContext.maxPage) {
        this.$refs.ProgressModal.close();
        this.$refs.BulkResultModal.open();
        this.getList();
        // console.log(this.bulkUploadContext.errors);
        return;
      }
      // 현재 페이지의 N개 ($resource.BULK_UPLOAD_CNT) 를 잘라 targetItems로 만든다.
      let targetItems = this.bulkUploadContext.items.slice(
        this.$resource.BULK_UPLOAD_CNT*this.bulkUploadContext.current,
        Math.min(this.$resource.BULK_UPLOAD_CNT*(this.bulkUploadContext.current+1), this.bulkUploadContext.items.length)
      );

      this.$api.AddBranches((result) => {
        // console.log(result.data);
        for (result of result.data.result) {
          if (!result.result) {
            this.bulkUploadContext.errors.push(result);
          } else {
            this.bulkUploadContext.okCnt++;
          }
        }
        this.bulkUploadContext.current++;
        this.addBulk();
      }, (error) => {
        console.error(error);
        // TODO. 에러 메시지 표시
      }, {
        page_total: this.bulkUploadContext.maxPage,
        page_current: this.bulkUploadContext.current,
        items: targetItems,
        headers: this.bulkUploadContext.header
      });
    },
    onSearch: function(text) {
      this.searchText = text;
      this.getList();
    },
    onAdd: function() {
      this.$refs.AddModal.open();
    },
    onAddPayload: function(payload) {
      axios.post(`/api/install/branches/`, payload)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 추가되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onEditPayload: function(payload) {
      axios.put(`/api/install/branches/${this.selected_branch.id}/`, payload)
        .then(result => {
          this.getList();
          this.selected_branch = result.data;
          this.$notify.success({
            title: '완료',
            message: `항목이 변경되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onFileChange: function(e) {
      const files = e.target.files;
      const reader = new FileReader;
      if (files.length !== 1) return;
      reader.onload = event => {
        let payload = event.target.result;
        payload = payload.replace(/[\r\n]+$/, '');
        payload = utility.CSVToArray(payload);

        // Context init & cascaded function call
        this.bulkUploadContext.maxPage = parseInt((payload.length-1) / this.$resource.BULK_UPLOAD_CNT);
        this.bulkUploadContext.current = 0;
        this.bulkUploadContext.header = payload[0];
        this.bulkUploadContext.items = payload.slice(1);
        this.bulkUploadContext.okCnt = 0;
        this.bulkUploadContext.errors = [];
        this.$refs.ConfirmModal.open();
      }
      reader.readAsText(files[0]);
    },
    onUpdatePage: function(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    bulkSubmit: function() {
      this.$refs.ProgressModal.open();
      this.addBulk();
    },
    onStopBulkUpload: function() {
      this.bulkUploadContext.current = this.bulkUploadContext.maxPage + 1;
      this.$refs.ProgressModal.close();
    },

    onView: function(id, index) {
      this.is_view = true;
      this.selected_branch = this.extractBranchById(id);
      this.selected_branch_index = index;
    },
    onMovePage : function(type){
      if(type==="branches"){
        this.is_view=false;
      }else if(type==="before_branch"){
        this.selected_branch_index = this.selected_branch_index-1;
        if(this.selected_branch_index < 0 ){
          this.selected_branch_index = this.list.length-1;
        }
        this.selected_branch = this.list[this.selected_branch_index];
      }else if(type==="after_branch"){
        this.selected_branch_index = this.selected_branch_index+1;
        if(this.selected_branch_index >= this.list.length){
          this.selected_branch_index = 0;
        }
        this.selected_branch = this.list[this.selected_branch_index];
      }
    },
    // onBranches: function(){
    //   this.is_view=false;
    // },
    // onBeforeBranch : function(){
    //   this.selected_branch_index = this.selected_branch_index-1;
    //   if(this.selected_branch_index < 0 ){
    //     this.selected_branch_index = this.list.length-1;
    //   }
    //   this.selected_branch = this.list[this.selected_branch_index];
    // },
    // onAfterBranch : function(){
    //   this.selected_branch_index = this.selected_branch_index+1;
    //   if(this.selected_branch_index >= this.list.length){
    //     this.selected_branch_index = 0;
    //   }
    //   this.selected_branch = this.list[this.selected_branch_index];
    // },
    onDelete: function(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onDeleteCheckedItems : function(delete_items){
      const fd = new FormData;
      let id_list = delete_items.map((item)=>{
        return item.id;
      })
      fd.append("id_list", JSON.stringify(id_list))
      axios.delete(`/api/install/branches/`, {data:fd})
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 변경되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
          this.$notify.error({
            title: '오류',
            message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        });
    },

    onConfirmed: function(payload) {
      axios.delete(`/api/install/branches/${payload}/`)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    },

    extractBranchById: function(id){
      for(let i=0; i<this.list.length; i++){
        if(this.list[i].id===id){
          return this.list[i];
        }
      }
      console.log("list has not id("+id+")");
      return null;
    },
    
  }
}
</script>
